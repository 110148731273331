import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as Globals from '../Globals';
import * as Utilities from '../utils/Utilities';
import * as TeamTickerInfo from './TeamTickerInfo';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Unpublished';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';

import { BasketballBall } from '@emotion-icons/fa-solid/BasketballBall';
import { FootballBall } from '@emotion-icons/fa-solid/FootballBall';
import { BaseballBall } from '@emotion-icons/fa-solid/BaseballBall';
import { SportsSoccer } from '@emotion-icons/material/SportsSoccer';
import { Team } from '@emotion-icons/remix-fill/Team';
import { Medal } from '@emotion-icons/fa-solid/Medal';

import { WeatherPartlySunny } from '@emotion-icons/typicons/WeatherPartlySunny';

import
{
	APPSTATUS_ENUM,
	selectJWT,
	selectRefreshToken,
	loadTeamInfo,
	selectTeamInfo,
	selectLoadTeamInfoStatus,
	resetLoadTeamInfoStatus,
	loadSheetsRevisionInfo,
	selectSheetsRevisionInfo,
	selectLoadSheetsRevisionInfoStatus,
	resetLoadSheetsRevisionInfoStatus,
	loadTeamFileInfo,
	selectTeamFileInfo,
	selectLoadTeamFileInfoStatus,
	resetLoadTeamFileInfoStatus,
	saveGameData,
	selectSaveGameDataStatus,
	resetSaveGameDataStatus,
	deleteGameData,
	selectDeleteGameDataStatus,
	resetDeleteGameDataStatus,
	publishGameData,
	selectPublishGameDataStatus,
	resetPublishGameDataStatus,
	selectPublishResultsInfo,
	clearPublishResultsInfo
} from '../store/appSlice';

const SHEETID_TEAMS = "1qOFDyO5bU12Esr8fX1erAXRv406ZAWU9nJEDwUDRUJM"; //"151GPgdAxj3AMoKDiyAKWPxUSBbVhXv6mliOeoBnFbHU";
const SHEETID_TEAMSTATS = "1g90wB__WW_FeAxz64MOHHtQpi7yZP-TziShQvJtevLU";
const SHEETID_SCHEDULE_FOOTBALL = "1kE5yUyNFfOuCj85AgamfBgz6Pns2aXMgXTdMgbBXGMA";
const SHEETID_SCHEDULE_MBASKETBALL = "1hxRY4MZktirUoSd3HG4YfQ_IGY_EBUUojpbmODqC06A";
const SHEETID_SCHEDULE_WBASKETBALL = "1IkvYhiujM3jIOJANiC94doTbER7MX43ZO-zV7kto-Mc";
const SHEETID_SCHEDULE_WSOCCER = "1rngpJnKvbUMq-H01w_7fteyXmM9itL0wy61gczKDwhc";
const SHEETID_SCHEDULE_BASEBALL = "1lL4guGK1Z7UU0wowFBqjDMAo5BQ-lAmd1jHHvF1wSu8";
const SHEETID_SCHEDULE_SOFTBALL = "15E6Xl8HYVqPQPcp0piHqBvX3JPFDXHxeIdIsVWUifsk";
const SHEETID_SCHEDULE_UPDATES = "1s8XBIVL_9bbExBX_KAZIfMr9aMxhgb0yrO0S5F5Wx1A";

/*
	{
		name: "Team and Player Stats",
		sheetId: SHEETID_TEAMSTATS
	},
*/

const EXTRAS = [
	{
		name: "Teams (abbreviations, colors, geolocation, mapping, w2w)",
		sportId: "team",
		sheetId: SHEETID_TEAMS
	},
	{
		name: "Schedule Football",
		sportId: "football",
		sheetId: SHEETID_SCHEDULE_FOOTBALL
	},
	{
		name: "Schedule Women's Soccer",
		sportId: "wsoccer",
		sheetId: SHEETID_SCHEDULE_WSOCCER
	},
	{
		name: "Schedule Men's Basketball",
		sportId: "mbasketball",
		sheetId: SHEETID_SCHEDULE_MBASKETBALL
	},
	{
		name: "Schedule Women's Basketball",
		sportId: "wbasketball",
		sheetId: SHEETID_SCHEDULE_WBASKETBALL
	},
	{
		name: "Schedule Baseball",
		sportId: "baseball",
		sheetId: SHEETID_SCHEDULE_BASEBALL
	},
	{
		name: "Schedule Softball",
		sportId: "softball",
		sheetId: SHEETID_SCHEDULE_SOFTBALL
	},
	{
		name: "Schedule Updates",
		sportId: "schedule-updates",
		sheetId: SHEETID_SCHEDULE_UPDATES
	}
];

let myOpenTabs = {};

function LoadingSpinner()
{
	return <>
		<Stack spacing={2} alignItems="center" justifyContent="center" mt={4}>
			<CircularProgress size={40} color="primary" />
			<Typography variant={"subtitle1"} mb={2}>Loading&hellip;</Typography>
		</Stack>
	</>;
};

function getSportIcon(sportId)
{
	let ret = <></>;

	let size = 18;
	let spacing = 8;
	switch (sportId)
	{
		case "mbasketball":
		case "wbasketball":
			ret = <BasketballBall style={{ paddingRight: spacing }} width={size} />
			break;
		case "football":
			ret = <FootballBall style={{ paddingRight: spacing }} width={size} />
			break;
		case "baseball":
		case "softball":
			ret = <BaseballBall style={{ paddingRight: spacing }} width={size} />
			break;
		case "wsoccer":
		case "msoccer":
			ret = <SportsSoccer style={{ paddingRight: spacing }} width={size} />
			break;
		case "team":
		case "schedule-updates":
			ret = <Team style={{ paddingRight: spacing }} width={size} />
			break;
		case "olympics":
			ret = <Medal style={{ paddingRight: spacing }} width={size} />
			break;
	}

	return ret;
}

const ENV_DISPLAY_INFO = {
	"dev": {
		name: "Development"
	},
	"stage": {
		name: "Staging"
	},
	"prerel": {
		name: "Pre-Release"
	},
	"prod": {
		name: "Production"
	},
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 16,
		fontWeight: 700,
		padding: 8,
		paddingLeft: 12,
		paddingRight: 12,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		padding: 8,
		paddingLeft: 12,
		paddingRight: 12,
	},
	[`&.${tableCellClasses.footer}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 16,
		fontWeight: 700,
		padding: 8,
		paddingLeft: 12,
		paddingRight: 12,
	},
}));

function ShowPublishResults()
{
	const dispatch = useDispatch();

	const handleClose = () =>
	{
		dispatch(clearPublishResultsInfo());
	};

	const publishResultsInfo = useSelector(selectPublishResultsInfo);

	return <>
		<div style={{ width: "100%" }}>
			<div>
				<Button onClick={() => { handleClose(); }} variant="contained">
					<>Close</>
				</Button>
			</div>
			<div style={{ height: 20 }}>
			</div>
			<div>
				{
					publishResultsInfo.lines.map((line, i) =>
					{
						line = line.trim();

						let addToLine = <></>;
						if (line.startsWith("INFO:"))
						{
							addToLine = <span style={{ color: 'green' }}>INFO:</span>;
							line = line.substr("INFO:".length);
						}
						else if (line.startsWith("WARNING:"))
						{
							addToLine = <span style={{ color: 'orange' }}>WARNING:</span>;
							line = line.substr("WARNING:".length);
						}
						else if (line.startsWith("WARN:"))
						{
							addToLine = <span style={{ color: 'orange' }}>WARNING:</span>;
							line = line.substr("WARN:".length);
						}
						else if (line.startsWith("ERROR:"))
						{
							addToLine = <span style={{ color: 'red' }}>ERROR:</span>;
							line = line.substr("ERROR:".length);
						}

						return <>{addToLine}{line}<br /></>;
					})
				}
			</div>
			<div style={{ height: 50 }}>
			</div>
		</div>
	</>;
}

const ERROROBJ_EMPTY = { severity: "none", message: "" };

function MainForm()
{
	const dispatch = useDispatch();

	const [teamUploadRunning, setTeamUploadRunning] = React.useState({});
	const [teamDeleteRunning, setTeamDeleteRunning] = React.useState({});
	const [refreshRunning, setRefreshRunning] = React.useState("");
	const [publishRunning, setPublishRunning] = React.useState({});
	const [errorObj, setErrorObj] = React.useState(ERROROBJ_EMPTY);

	const jwt = useSelector(selectJWT);
	const refreshToken = useSelector(selectRefreshToken);

	const loadTeamInfoStatus = useSelector(selectLoadTeamInfoStatus);
	const loadSheetsRevisionInfoStatus = useSelector(selectLoadSheetsRevisionInfoStatus);
	const loadTeamFileInfoStatus = useSelector(selectLoadTeamFileInfoStatus);
	const saveGameDataStatus = useSelector(selectSaveGameDataStatus);
	const deleteGameDataStatus = useSelector(selectDeleteGameDataStatus);
	const publishGameDataStatus = useSelector(selectPublishGameDataStatus);

	const teamInfo = useSelector(selectTeamInfo);
	const sheetsRevisionInfo = useSelector(selectSheetsRevisionInfo);
	const teamFileInfo = useSelector(selectTeamFileInfo);
	const publishResultsInfo = useSelector(selectPublishResultsInfo);

	const allLoaded = (teamInfo.loaded && sheetsRevisionInfo.loaded && teamFileInfo.loaded);

	const loadInfoRunning = (loadTeamInfoStatus.status === APPSTATUS_ENUM.LOADING || loadSheetsRevisionInfoStatus.status === APPSTATUS_ENUM.LOADING);

	let envs = ["stage", "prod"];

	React.useEffect(() =>
	{
		let contextObj = { jwt, refreshToken };

		//dispatch(loadTeamInfo({ ...contextObj }));
		dispatch(loadSheetsRevisionInfo({ ...contextObj }));
		dispatch(loadTeamFileInfo({ ...contextObj }));

	}, []);

	React.useEffect(() =>
	{
		if (loadTeamInfoStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			dispatch(resetLoadTeamInfoStatus());
		}
		else if (loadTeamInfoStatus.status === APPSTATUS_ENUM.FAILED)
		{
			setErrorObj({ severity: "error", message: loadTeamInfoStatus.errorMsg });

			dispatch(resetLoadTeamInfoStatus());
		}
	}, [loadTeamInfoStatus]);

	React.useEffect(() =>
	{
		if (loadSheetsRevisionInfoStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			dispatch(resetLoadSheetsRevisionInfoStatus());
		}
		else if (loadSheetsRevisionInfoStatus.status === APPSTATUS_ENUM.FAILED)
		{
			setErrorObj({ severity: "error", message: loadSheetsRevisionInfoStatus.errorMsg });

			dispatch(resetLoadSheetsRevisionInfoStatus());
		}
	}, [loadSheetsRevisionInfoStatus]);

	React.useEffect(() =>
	{
		if (loadTeamFileInfoStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			dispatch(resetLoadTeamFileInfoStatus());
		}
		else if (loadTeamFileInfoStatus.status === APPSTATUS_ENUM.FAILED)
		{
			setErrorObj({ severity: "error", message: loadTeamFileInfoStatus.errorMsg });

			dispatch(resetLoadTeamFileInfoStatus());
		}
	}, [loadTeamFileInfoStatus]);

	React.useEffect(() =>
	{
		if (saveGameDataStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			setTeamUploadRunning({});
			dispatch(resetSaveGameDataStatus());
		}
		else if (saveGameDataStatus.status === APPSTATUS_ENUM.FAILED)
		{
			setErrorObj({ severity: "error", message: saveGameDataStatus.errorMsg });

			setTeamUploadRunning({});
			dispatch(resetSaveGameDataStatus());
		}
	}, [saveGameDataStatus]);

	React.useEffect(() =>
	{
		if (deleteGameDataStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			setTeamDeleteRunning({});
			dispatch(resetDeleteGameDataStatus());
		}
		else if (deleteGameDataStatus.status === APPSTATUS_ENUM.FAILED)
		{
			setErrorObj({ severity: "error", message: deleteGameDataStatus.errorMsg });

			setTeamDeleteRunning({});
			dispatch(resetDeleteGameDataStatus());
		}
	}, [deleteGameDataStatus]);

	React.useEffect(() =>
	{
		if (publishGameDataStatus.status === APPSTATUS_ENUM.SUCCESS)
		{
			setPublishRunning({});
			dispatch(resetPublishGameDataStatus());
		}
		else if (publishGameDataStatus.status === APPSTATUS_ENUM.FAILED)
		{
			setErrorObj({ severity: "error", message: publishGameDataStatus.errorMsg });

			setPublishRunning({});
			dispatch(resetPublishGameDataStatus());
		}
	}, [publishGameDataStatus]);

	const openSpreadsheetInNewTab = (sheetId) =>
	{
		let url = "https://docs.google.com/spreadsheets/d/" + sheetId;
		let name = "tt.window." + sheetId;

		let openWindow = myOpenTabs[name];

		if (!openWindow || openWindow.closed)
		{
			openWindow = window.open(url, name);
			myOpenTabs[name] = openWindow;
		}
		else
		{
			openWindow.focus();
		}
	}

	const handleAllUpload = (uploadContextObj) =>
	{
		setErrorObj(ERROROBJ_EMPTY);
		setTeamUploadRunning(uploadContextObj);
		try
		{
			dispatch(saveGameData(uploadContextObj));
		}
		catch (err)
		{
			console.log("ERROR: handleTeamUpload: " + err);
		}
	}

	const handleTeamUpload = (uploadContextObj) => 
	{
		setErrorObj(ERROROBJ_EMPTY);
		setTeamUploadRunning(uploadContextObj);
		try
		{
			dispatch(saveGameData(uploadContextObj));
		}
		catch (err)
		{
			console.log("ERROR: handleTeamUpload: " + err);
		}
	}

	const isTeamUploadSpinning = (uploadContextObj) =>
	{
		return (teamUploadRunning.env == uploadContextObj.env && teamUploadRunning.sheetId == uploadContextObj.sheetId);
	}

	const handleTeamDelete = (deleteContextObj) => 
	{
		setErrorObj(ERROROBJ_EMPTY);
		setTeamDeleteRunning(deleteContextObj);
		try
		{
			dispatch(deleteGameData(deleteContextObj));
		}
		catch (err)
		{
			console.log("ERROR: handleTeamUpload: " + err);
		}
	}

	const isTeamDeleteSpinning = (deleteContextObj) =>
	{
		return (teamDeleteRunning.env == deleteContextObj.env && teamDeleteRunning.sheetId == deleteContextObj.sheetId);
	}

	const handleRefreshAll = () =>
	{
		let contextObj = { jwt, refreshToken };

		setRefreshRunning("all");
		setErrorObj(ERROROBJ_EMPTY);

		try
		{
			dispatch(loadSheetsRevisionInfo({ ...contextObj }));
			dispatch(loadTeamFileInfo({ ...contextObj }));
		}
		catch (err)
		{
			console.log("ERROR: handleRefresh: " + err);
		}
	}

	const isRefreshAllSpinning = () =>
	{
		return (loadInfoRunning && refreshRunning == "all");
	}

	const handlePublish = (contextObj) =>
	{
		setErrorObj(ERROROBJ_EMPTY);
		setPublishRunning(contextObj);
		try
		{
			dispatch(publishGameData(contextObj));
		}
		catch (err)
		{
			console.log("ERROR: handlePubish: " + err);
		}
	}

	const isPublishSpinning = (contextObj) =>
	{
		return (publishRunning.env === contextObj.env);
	}

	const handleFileDown = async (callData) =>
	{
		setErrorObj(ERROROBJ_EMPTY);

		try
		{
			let bodyObj = {
				command: "getGameData",
				refreshToken: callData.refreshToken,
				env: "dev",
				sheetId: callData.sheetId,
				dataType: callData.dataType
			};

			let fetchOptions = {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + callData.jwt
				},

				body: JSON.stringify(bodyObj)
			};

			let response = await fetch(process.env.REACT_APP_PUBLISHER_API_URL + '/control', fetchOptions);

			if (response.status >= 200 && response.status < 300)
			{
				let json = await response.json();
				if (json && json.doc)
				{
					const str = JSON.stringify(json.doc, null, 2);
					const bytes = new TextEncoder().encode(str);
					const blob = new Blob([bytes], {
						type: "application/json;charset=utf-8"
					});

					const element = document.createElement("a");
					element.href = URL.createObjectURL(blob);
					element.download = callData.team + "-" + callData.sport + "-gamedata" + ".json"

					element.click();
				}
			}
		}
		catch (err)
		{
			console.log("ERROR: handleFileDown: " + err);
		}
	}

	let hitComponent = false;

	return <>
		{
			allLoaded ?
				publishResultsInfo.hasOwnProperty("lines") ?
					<ShowPublishResults info={publishResultsInfo} />
					:
					<>
						<div style={{ width: "100%", paddingRight: 10, paddingLeft: 10 }}>
							<div key={"buttons"}>
								<Button onClick={() => { handleRefreshAll(); }} variant="contained">
									<>Refresh</>
									{
										isRefreshAllSpinning() &&
										<>&nbsp;&nbsp;&nbsp;<CircularProgress size={20} style={{ color: 'white' }} /></>
									}
								</Button>
							</div>
							{
								errorObj.severity != "none" &&
								<div key={"error-message"} style={{ marginTop: 10 }}>
									<Alert severity="error" onClose={() => { setErrorObj(ERROROBJ_EMPTY); }}>{errorObj.message}</Alert>
								</div>
							}
							<div key={"maincontent"} style={{ paddingTop: 10, paddingBottom: 20 }}>
								<TableContainer key={"table-container"} component={Paper}>
									<Table key={"table"} sx={{ minWidth: 700 }} aria-label="customized table">
										<TableHead key={"t-header"}>
											<TableRow key={"header"}>
												<StyledTableCell key={"head-univ"}>&nbsp;</StyledTableCell>
												<StyledTableCell key={"head-sport"}>Sport</StyledTableCell>
												{
													envs.map((env, i) =>
													{
														return <StyledTableCell key={"head-" + env} align="center">{ENV_DISPLAY_INFO[env].name}</StyledTableCell>
													})
												}
											</TableRow>
											<TableRow key={"footer"}>
												<StyledTableCell key={"footer-cell-0"}></StyledTableCell>
												<StyledTableCell key={"footer-cell-1"}></StyledTableCell>
												{
													envs.map((env, i) =>
													{
														let publishContextObj = {
															jwt,
															refreshToken,
															env: env,
														}

														let allUploadContextObj = {
															jwt,
															refreshToken,
															refreshAllInEnv: true,
															dataType: "teamData",
															env: env,
														}

														return <StyledTableCell key={"footer-cell-2-" + env} align="center">
															<Button key={"footer-cell-2-button-update" + env} variant="contained" size="small" onClick={() => { handleAllUpload(allUploadContextObj); }}>
																<><FileUploadIcon />&nbsp;{"All"}</>
																{
																	isTeamUploadSpinning(publishContextObj) &&
																	<>&nbsp;&nbsp;&nbsp;<CircularProgress size={20} style={{ color: 'white' }} /></>
																}
															</Button>
															&nbsp;&nbsp;&nbsp;
															<Button key={"footer-cell-2-button-publish" + env} variant="contained" size="small" onClick={() => { handlePublish(publishContextObj); }}>
																<>{"Publish"}</>
																{
																	isPublishSpinning(publishContextObj) &&
																	<>&nbsp;&nbsp;&nbsp;<CircularProgress size={20} style={{ color: 'white' }} /></>
																}
															</Button>
														</StyledTableCell>
															;
													})
												}
											</TableRow>
										</TableHead>
										<TableBody key={"t-body"}>
											{
												Object.keys(teamInfo.data?.teamInfo?.allItems).map((teamId, i) =>
												{
													let team = teamInfo.data?.teamInfo?.allItems[teamId];

													let teamName = team.hasOwnProperty("name") ? team.name : "Unknown";

													let insertDivider = false;
													if (!hitComponent && team.dataType === "componentData")
													{
														hitComponent = true;
														insertDivider = true;
													}

													return <React.Fragment key={"frag-teamName-" + teamId}>
														{
															insertDivider &&
															<TableRow key={"divider"}>
																<StyledTableCell key={"cell-teamName-" + teamId} colSpan={2 + envs.length} style={{ background: "black" }}></StyledTableCell>
															</TableRow>
														}
														<TableRow key={"teamName-" + teamId}>
															<StyledTableCell key={"cell-teamName-" + teamId} colSpan={2 + envs.length}><span style={{ fontSize: "120%", fontWeight: 600 }}>{teamName}</span></StyledTableCell>
														</TableRow>
														{
															Object.keys(team.sports).map((sportId, i) =>
															{
																let sport = team.sports[sportId];

																let sheetId = sport.sheetId;

																let sheetRevision = sheetsRevisionInfo?.data?.sheetRevisions?.[sheetId];
																let sheetRevisionModified = undefined;
																if (sheetRevision)
																{
																	let localDate = Utilities.parseDate(sheetRevision.modifiedTime);
																	sheetRevisionModified = Utilities.formateDate(localDate);
																}

																let tooltip = <>
																	<div style={{ fontSize: "small", padding: 8 }}>
																		<div>
																			<div style={{ textDecoration: "underline" }}>Spreadsheet Info</div>
																			<div>Revision: {sheetRevision ? sheetRevision.id : "[unknown]"}</div>
																			<div>Modified: {sheetRevisionModified ? sheetRevisionModified : "[unknown]"}</div>
																		</div>
																	</div>
																</>;

																let downloadContextObj = {
																	jwt,
																	refreshToken,
																	team: teamId,
																	sport: sportId,
																	sheetId: sheetId,
																	dataType: team.dataType
																}

																/*
																<Tooltip title={tooltip} arrow>
																</Tooltip>
																*/

																return <TableRow key={"sheet-" + teamId + "-" + sportId}>
																	<StyledTableCell key={"cell-0-sheet-" + teamId + "-" + sportId}></StyledTableCell>
																	<StyledTableCell key={"cell-1-sheet-" + teamId + "-" + sportId}>

																		<Stack direction="row" spacing={1}>
																			<div key={"i1"} style={{ paddingTop: 11 }}>
																				<Link style={{ cursor: "pointer" }} onClick={() => { openSpreadsheetInNewTab(sheetId); }}>
																					<span style={{ fontSize: "120%", fontWeight: 500, display: "inline-flex" }}>{getSportIcon(sportId)}{TeamTickerInfo.SPORT_DISPLAY_INFO[sportId].name}</span>
																				</Link>
																			</div>
																			<div key={"i3"} style={{ paddingTop: 0 }}>
																				<Tooltip enterDelay={1000} title="Download JSON" arrow>
																					<IconButton onClick={() => { handleFileDown(downloadContextObj); }}>
																						<FileDownloadIcon />
																					</IconButton>
																				</Tooltip>
																			</div>
																		</Stack>

																	</StyledTableCell>
																	{
																		envs.map((env, i) =>
																		{
																			let envInfo = teamFileInfo.data?.[env];

																			let fileRevision = envInfo?.[teamId]?.sports?.[sportId]?.revision;
																			let fileRevisionModified = undefined;
																			if (fileRevision)
																			{
																				let localDate = Utilities.parseDate(fileRevision.modifiedTime);
																				fileRevisionModified = Utilities.formateDate(localDate);
																			}

																			let fileSaveDateStr = envInfo?.[teamId]?.sports?.[sportId]?.date;
																			let fileSaveDate = undefined;
																			if (fileSaveDateStr)
																			{
																				let localDate = Utilities.parseDate(fileSaveDateStr);
																				fileSaveDate = Utilities.formateDate(localDate);
																			}

																			let isGood = (sheetRevision && fileRevision && sheetRevision.id === fileRevision.id);

																			let tooltip = <>
																				<div style={{ fontSize: "small", padding: 8 }}>
																					<div key={"j1"} style={{ fontSize: "medium", paddingBottom: 8 }}>{ENV_DISPLAY_INFO[env].name}</div>
																					<div key={"j2"} style={{ height: 6 }}></div>
																					{
																						isGood ?
																							<div>File matches sheet</div>
																							:
																							<>
																								{
																									fileRevision ?
																										<div>File does not match sheet</div>
																										:
																										<div>File does not exist</div>
																								}
																							</>
																					}
																					<div key={"j3"} style={{ height: 10 }}></div>
																					<div key={"j4"}>
																						<div style={{ textDecoration: "underline" }}>Spreadsheet Info</div>
																						<div>Revision: {sheetRevision ? sheetRevision.id : "[unknown]"}</div>
																						<div>Modified: {sheetRevisionModified ? sheetRevisionModified : "[unknown]"}</div>
																					</div>
																					<div key={"j5"} style={{ height: 10 }}></div>
																					{
																						fileRevision &&
																						<div>
																							<div style={{ textDecoration: "underline" }}>File Info</div>
																							<div>Revision: {fileRevision ? fileRevision.id : "[unknown]"}</div>
																							<div>Modified: {fileRevisionModified ? fileRevisionModified : "[unknown]"}</div>
																							<div>Uploaded: {fileSaveDate ? fileSaveDate : "[unknown]"}</div>
																						</div>
																					}
																				</div>
																			</>;

																			let uploadContextObj = JSON.parse(JSON.stringify(downloadContextObj));

																			uploadContextObj.env = env;
																			uploadContextObj.refreshAllInEnv = false;

																			return <StyledTableCell key={"cell-2-sheet-" + teamId + "-" + sportId + "-" + env} align="center">
																				<Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
																					<div key={"k1"} style={{ paddingTop: 1, margin: 0 }}>
																						<Tooltip enterDelay={1000} title={"Upload Sheet to " + ENV_DISPLAY_INFO[env].name} arrow>
																							<IconButton onClick={() => { handleTeamUpload(uploadContextObj); }}>
																								{
																									isTeamUploadSpinning(uploadContextObj) ?
																										<CircularProgress size={24} color="primary" />
																										:
																										<FileUploadIcon />
																								}
																							</IconButton>
																						</Tooltip>
																					</div>
																					<div key={"k2"} style={{ paddingTop: 0, margin: 0 }}>
																						<Tooltip enterDelay={1000} title={"Delete File from " + ENV_DISPLAY_INFO[env].name} arrow>
																							<IconButton onClick={() => { handleTeamDelete(uploadContextObj); }}>
																								{
																									isTeamDeleteSpinning(uploadContextObj) ?
																										<CircularProgress size={24} color="primary" />
																										:
																										<DeleteIcon />
																								}
																							</IconButton>
																						</Tooltip>
																					</div>
																					<div key={"k3"} style={{ paddingTop: 6, margin: 0, paddingLeft: 5, paddingRight: 5 }}>
																						{
																							isGood ?
																								<Tooltip title={tooltip} arrow>
																									<CheckCircleIcon color="success" />
																								</Tooltip>
																								:
																								<>
																									{
																										fileRevision ?
																											<Tooltip title={tooltip} arrow>
																												<WarningIcon color="warning" />
																											</Tooltip>
																											:
																											<Tooltip title={tooltip} arrow>
																												<ErrorIcon color="info" />
																											</Tooltip>
																									}
																								</>
																						}
																					</div>
																				</Stack>
																			</StyledTableCell>
																		})
																	}
																</TableRow >

															})
														}
													</React.Fragment>
												})
											}
										</TableBody >
									</Table >
								</TableContainer >
								<div className="extra-tools-container">
									<div className="extra-tools-title">Tools</div>
									<div className="extra-tools-inner">
										{
											EXTRAS.map((extra, i) =>
											{
												return <div key={"extra" + i} className="extra-tools">
													<Stack direction="row" spacing={1}>

														{
															extra.sportId ?
																<>
																	<div key={"extra" + i + "-1"} style={{ paddingTop: 11 }}>
																		<Link style={{ cursor: "pointer" }} onClick={() => { openSpreadsheetInNewTab(extra.sheetId); }}>
																			<span style={{ fontSize: "120%", fontWeight: 500, display: "inline-flex" }}>{getSportIcon(extra.sportId)}{extra.name}</span>
																		</Link>
																	</div>
																</>
																:
																<>
																	<div key={"extra" + i + "-1"} style={{ paddingTop: 11 }}>
																		<Link style={{ cursor: "pointer" }} onClick={() => { openSpreadsheetInNewTab(extra.sheetId); }}>
																			<img src={process.env.PUBLIC_URL + "/sheet-icon.png"} width="16" height="16" />
																		</Link>
																	</div>
																	<div key={"extra" + i + "-2"} style={{ paddingTop: 9 }}>
																		<Link style={{ cursor: "pointer" }} onClick={() => { openSpreadsheetInNewTab(extra.sheetId); }}>
																			<Typography variant={"subtitle2"}>{extra.name}</Typography>
																		</Link>
																	</div>
																</>
														}

													</Stack>
												</div>

											})
										}
									</div>
								</div>
							</div>
						</div>
					</>
				:
				<><LoadingSpinner /></>
		}
	</>;
}

export default MainForm;
